import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		unit: '元',
		addressData:{}
	},
	getters: {
	},
	mutations: {
		updateUnit(state, data) {
			state.unit = data
		},
		updateAddressData(state, data) {
			state.addressData = data
		}
	},
	actions: {
	},
	modules: {
	}
})
