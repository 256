import service from "@/utils/request"

// 分页查询首页商品
// export function findGoodsApi(params) {
//     return service({
//         url: '/indexWxLMH5Two/getCommodityListByTopicId.action',
//         method: 'get',
//         params
//     })
// }
//获取店铺信息
export function getShopTypeApi(params) {
	return service({
		url: '/indexWxZLD/getMyShopByH5',
		method: 'post',
		data: params
	})
}
//获取二级分类数据
export function getCateDataApi(params) {
	console.log(params);
	return service({
		url: '/indexWxZLD/getCategory',
		method: 'post',
		data: params
	})
}
//商品列表查询
export function getGoodsDataApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/findComboVoucherGoodsList',
		method: 'post',
		data: params, isShow: true
	})
}
//活动商品列表查询
export function getActiveGoodsApi(params) {
	return service({
		url: '/indexWxZLD/activityThemeGoods',
		method: 'post',
		data: params, isShow: true
	})
}
//商品详情查询
export function getGoodsDetailApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getComboVoucherGoodsDetails',
		method: 'get',
		params, isShow: true
	})
}
//商品详情查询
export function addOrderApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/addComboVoucherOrder',
		method: 'post',
		data:params, isShow: true
	})
}

//活动专区展示
export function getShopTopicListApi(params) {
	return service({
		url: '/indexWxZLDh5/getShopTopicList',
		method: 'get',
		params
	})
}
//订单查询
export function getMyOrderApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getDetailRecord',
		method: 'post',
		data: params
	})
}

//修改商品数量
export function upDateGoodsNumApi(params) {
	return service({
		url: '/indexWxZLD/updateCartNumByH5',
		method: 'post',
		data: params
	})
}


//地址
//获取默认地址
export function getAddressDefaultApi(params) {
	return service({
		url: '/indexWxZLDh5/getAddressDefault',
		method: 'get',
		params
	})
}
//获取地址列表
export function getAddressListApi(params) {
	return service({
		url: '/indexWxZLDh5/getMyAddressList',
		method: 'post',
		data: params
	})
}
//获取省市区数据
export function getProCityAreaApi(params) {
	return service({
		url: '/indexWxZLD/findJdregion',
		method: 'get',
		params
	})
}
//新增地址信息
export function addAddressApi(params) {
	return service({
		url: '/indexWxZLDh5/addAddress',
		method: 'post',
		data: params, isShow: true
	})
}
//编辑地址信息
export function editAddressApi(params) {
	return service({
		url: '/indexWxZLD/updateAddress',
		method: 'post',
		data: params
	})
}
//删除地址信息
export function removeAddressApi(params) {
	return service({
		url: '/indexWxZLD/deletAddress',
		method: 'post',
		data: params
	})
}

//登录
//获取验证码
export function sendCodeApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/sendCode',
		method: 'post',
		data: params, isShow: true
	})
}
//手机号验证码登录
export function loginBySmsApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/login',
		method: 'post',
		data: params, isShow: true
	})
}
//卡号卡密登录
export function loginByGiftbagApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getCheckVoucher',
		method: 'post',
		data: params, isShow: true
	})
}
//绑定礼包卡号卡密
export function bindGiftbagApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getVoucher',
		method: 'post',
		data: params, isShow: true
	})
}

//用户
//获取用户信息
export function getUserInfoApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getUserInfo',
		method: 'get',
		params
	})
}



// //积分明细
// export function getExchangeRecordApi(params) {
// 	return service({
// 		url: '/indexWxZLD/getMyJpScoreByH5',
// 		method: 'post',
// 		data: params, isShow: true
// 	})
// }
//获取卡券信息
export function getComboVoucherInfoApi(params) {
	return service({
		url: '/ecardComboGiftVoucher/getComboVoucherInfo',
		method: 'get',
		params,
		isShow: true
	})
}