<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
		<van-tabbar route active-color="#db0320" placeholder v-show="$route.meta.showTabbar" fixed>
			<van-tabbar-item replace to="/" icon="point-gift-o">礼包挑选</van-tabbar-item>
			<van-tabbar-item replace to="/user" icon="user">个人中心</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<style>
* {
	padding: 0;
	margin: 0;
	/*font-family: "Microsoft YaHei", "Heiti SC", tahoma, arial, "Hiragino Sans GB", 宋体, sans-serif;*/
	font-family: -apple-system, Helvetica, sans-serif;
}

div {
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
}

.flex_sb {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

html,
body {
	background: #f6f6f6;
}

.van-nav-bar .van-icon {
	color: #333 !important;
}

.van-nav-bar__text {
	color: #333 !important;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	background-color: #fff;

}
</style>

<script>
import {getComboVoucherInfoApi} from "@/api";

export default {
  name:"APP",
  data() {
    return{

    }
  },
  methods:{
    async getComboVoucherInfo() {
      let { data }=await getComboVoucherInfoApi({
        comboVoucherId:localStorage.getItem("comboVoucherId")
      });

      document.title=data.title || "三江智采";
    },
  },
  created() {
    this.getComboVoucherInfo();
  }
}
</script>
