<template>
	<div id="user">
		<div class="headerBox">
			<div class="headerTitle">
				个人中心
			</div>
			<div class="userMsgBox" style="margin-top: 20px;">
				<img style="width: 70px;height: 70px;border-radius: 50%;margin-right: 26px;"
					:src="userInfo.headImg != null ? userInfo.headImg : 'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'">
				<div class="editUserInfoBtn" @click="logout">退出登录</div>
			</div>
			<div class="balanceBox">
				<div style="color: #FFB12E;">
					<div style="font-size: 14px;font-weight: 600;">兑换次数: <span style="font-size: 18px;">{{
						userInfo.numberOfUse ? userInfo.numberOfUse : 0
					}} </span>次
					</div>
				</div>
				<!-- <div class="toExchangeBtn" @click="$router.push('/exchangePage')">立即兑换</div> -->
			</div>
		</div>
		<div class="orderTypeBox">
			<div class="orderTypeTitle">
				<div style="color: #333;font-size: 16px;font-weight: 600;">我的订单</div>
				<!-- <div style="color: #A1A1A1;font-size:14px;">查看更多<van-icon name="arrow" /></div> -->
			</div>
			<div class="orderTypeList">
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 0 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/待发货@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum">0</div> -->
					<div>待发货</div>
				</div>
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 1 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/待收货@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum">0</div> -->
					<div>待收货</div>
				</div>
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 2 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/已完成@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum" wx:if="{{finished&&finished!=0}}">{{finished}}</div> -->
					<div>已完成</div>
				</div>
			</div>
		</div>

		<div class="myServiceBox">
			<div class="myServiceTitle">我的服务</div>
			<div class="myServiceContent">
				<div class="myServiceItem" @click="$router.push('/exchangeRecord')">
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/点数2.png" />
					<div class="myServiceName">兑换明细</div>
				</div>
				<div class="myServiceItem" @click="$router.push('/addressList')">
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/address@2x.png" />
					<div class="myServiceName">地址管理</div>
				</div>
				<div class="myServiceItem">
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/客服.png" />
					<div class="myServiceName" @click="showKefu">联系客服</div>
				</div>
			</div>
		</div>

		<!-- <GoodsListWaterfall style="margin-top:20px;" :goods_data_left="goods_data_left"
      :goods_data_right="goods_data_right"></GoodsListWaterfall>
     <van-overlay :show="kefushow" @click="showKefu">
      <div class="wrapper">

      </div>
    </van-overlay> -->
		<van-action-sheet v-model="kefushow" title="客服联系方式">

		</van-action-sheet>

		<van-action-sheet v-model="userShow" title="修改个人信息">
			<van-field name="uploader" label="头像">
				<template #input>
					<van-uploader ref="uploader" v-model="fileList" :after-read="afterRead" multiple :max-count="1" />
				</template>
			</van-field>
			<van-field v-model="userName" label="用户名" placeholder="请输入用户名" />
			<div style="display:flex;width:94.6%;margin: 20px auto;">
				<div class="editUserInfoBtn" style="background: #999;margin-right:10px;" @click="removeUserInfo">退出登录</div>
				<div class="editUserInfoBtn" @click="editUserInfoApi">保存</div>
			</div>

		</van-action-sheet>
	</div>
</template>
<style scoped>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	width: 100%;
	border-radius: 10px;
	padding: 10px;
	background-color: #fff;
}

div {
	box-sizing: border-box;
}

.headerBox {
	width: 100%;
	background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/userBg@2x.png');
	background-size: 100% 100%;
	background-position: center;
	padding: 12px;
	/* position: relative; */
}

.headerTitle {
	text-align: center;
	color: #333;
	font-size: 18px;
	height: 20px;
	font-weight: 600;
	margin-top: 10px;
}

.userMsgBox {
	width: 100%;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.userName {
	font-size: 16px;
	font-family: PingFang SC;
	color: #333333;
}

.editBtn {
	width: 90px;
	padding: 2px 0;
	background: #FFFFFF;
	border: 1px solid #B5B5B5;
	border-radius: 10px;
	font-size: 13px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #666666;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.balanceBox {
	width: 100%;
	padding: 20px;
	border-radius: 13px 13px;
	background-color: #302e2b;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	margin-top: 20px;
}

.myServiceBox {
	width: 94.6%;
	/* height: 360px; */
	background: #FFFFFF;
	border-radius: 14px;
	margin: 0 auto;
	padding: 15px 10px;
	margin-top: 15px;
}

.myServiceTitle {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 600;
	color: #333333;
	margin-left: 10px;
}

.myServiceContent {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 10px;
}

.myServiceItem {
	width: 33%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.toExchangeBtn {
	padding: 6px 15px;
	background: linear-gradient(90deg, #DCA433 0%, #F2CE85 100%);
	border-radius: 16px;
	text-align: center;
	font-size: 14px;
	color: #666;
}

.orderTypeBox {
	width: 94.6%;
	/* height: 140px; */
	padding: 10px 0px;
	background-color: #fff;
	border-radius: 14px;
	margin: 0 auto;
	margin-top: 5px;
	margin-bottom: 10px;
}

.orderTypeTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
	color: #666;
	padding: 10px 20px;
	margin-bottom: 10px;
}

.orderTypeList {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.orderTypeItem {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 14px;
	justify-content: space-between;
}

.goodsNum {
	position: absolute;
	background-color: #f00;
	width: 17px;
	height: 17px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 17px;
	border-radius: 50%;
	right: -6px;
	top: -6px;
}

.editUserInfoBtn {
	width: 30%;
	height: 30px;
	line-height: 30px;
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-size: 14px;
	background: #aaaaaa;
}

.myServiceName {
	font-size: 14px;
}
</style>
<script>
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import { getUserInfoApi } from '@/api/index'
import { Toast, Dialog } from 'vant';

export default {
	//import引入的组件需要注入到对象中才能使用
	components: {
		GoodsListWaterfall,
	},
	data() {
		//这里存放数据
		return {
			goods_data_left: [],
			goods_data_right: [],
			kefushow: false,
			userShow: false,
			userInfo: {},
			userName: '',
			avatar: '',
			fileList: [],
			shopInfo: {},
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		logout() {
			Dialog.confirm({
				title: '提示',
				message: '是否确认退出登录?',
			}).then(() => {
				Toast.success('退出成功');
				setTimeout(() => {
					localStorage.removeItem('userToken')
					this.userInfo = {}
					this.$router.push({ path: '/login', params: { comboVoucherId: localStorage.getItem('comboVoucherId') } })
				}, 1000)
			})
		},
		afterRead(file) {
			console.log(file);
			// 此时可以自行将文件上传至服务器
			this.avatar = this.base64ToImage(file.content);
		},
		showKefu() {
			this.kefushow = !this.kefushow
		},
		async getUserInfo() {
			let { data } = await getUserInfoApi({
				comboVoucherId: localStorage.getItem('comboVoucherId')
			})
			this.userInfo = data
		},
		//修改用户信息方法
		editUserInfoApi() {

		},
		removeUserInfo() {
			Dialog.confirm({
				title: '提示',
				message: '是否确认退出登录?',
			}).then(() => {
				localStorage.removeItem('userToken')
				localStorage.removeItem('addressData')
				this.userInfo = {}
				this.$router.push('/')
			})
		},
		callPhone() {
			window.location.href = 'tel://' + this.shopInfo.customerTel
		}

	},
	beforeCreate() {
	}, //生命周期 - 创建之前
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		// if (this.$route.query.showKefu && localStorage.getItem('userToken')) {
		// 	this.kefushow = true
		// }
		// this.shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
		this.getUserInfo()
	},
	beforeMount() {
	}, //生命周期 - 挂载之前
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
	beforeUpdate() {
	}, //生命周期 - 更新之前
	updated() {
	}, //生命周期 - 更新之后
	beforeDestroy() {
	}, //生命周期 - 销毁之前
	destroyed() {
	}, //生命周期 - 销毁完成
	activated() {
	}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>