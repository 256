<!-- 首页 -->
<template>
	<div id='home'>
		<HomeComponents v-for="(item, index) in componentList" :key="index" :item="item"></HomeComponents>
		<div style="height:12px;"></div>
	</div>
</template>

<script>
import HomeComponents from "@/components/HomeComponents";
export default {
	//import引入的组件需要注入到对象中才能使用
	components: { HomeComponents },
	data() {
		//这里存放数据
		return {
			componentList: [
				{
					name: 'header-search'
				},
				{
					name: 'header-banner', url: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/eCardBanner/dragonYear.png'
				},
				{
					name: 'categoryArea'
				},
				{
					name: 'waterfallArea',
					url: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/image/goodsAreaTitle.png'
				}
			],
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {

	},
	beforeCreate() { },
	created() {
	},
	beforeMount() { },
	mounted() {

	},
	beforeUpdate() { },
	updated() { },
	beforeDestroy() { },
	destroyed() { },
	activated() { },
}
</script>
<style></style>