import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import User from '../views/User.vue'
Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			index: 0,
			keepAlive: true,//是否缓存
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			index: 1,
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/goodsDetail/:id?',
		name: 'GoodsDetail',
		component: () => import('../views/GoodsDetail.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/addressList',
		name: 'AddressList',
		component: () => import('../views/Address/AddressList.vue')
	},
	{
		path: '/editAddress',
		name: 'EditAddress',
		component: () => import('../views/Address/EditAddress.vue')
	},
	{
		path: '/orderDetail',
		name: 'OrderDetail',
		component: () => import('../views/OrderDetail.vue')
	},
	{
		path: '/orderRecord',
		name: 'OrderRecord',
		component: () => import('../views/Record/OrderRecord.vue')
	},
]



const router = new VueRouter({
	mode: "history",
	routes
})

router.beforeEach((to, from, next) => {
	// 如果是去个人中心页，要确认本地存储是否有token
	if (to.path != '/login') {
		if (!localStorage.getItem("userToken")) {
			//没有token就去往登录页
			next({
				name: 'Login',
				query: { comboVoucherId: to.query.comboVoucherId }
			});
		} else {
			//没有token就去往登录页
			next()
		}
	}
	next()
});

export default router
